import React, { useState, useEffect, useRef } from "react";
import { Button, Divider, Grid } from "@mui/material";
import { LocalMall, Star } from "@mui/icons-material";
import Slider from "@mui/material/Slider";
import { Link } from "react-router-dom";

import customizeJeep from "../assets/images/about-customize-jeep.png";
import jeep1 from "../assets/images/demoProduct1.png";
import jeep2 from "../assets/images/demoProduct2.png";
import jeep3 from "../assets/images/demoProduct3.png";

const ProductDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [products, setProducts] = useState([
    { name: "DONEC COMMANDO", imageIndex: 1 },
    { name: "DONEC COMMANDO", imageIndex: 2 },
    { name: "DONEC COMMANDO", imageIndex: 3 },
  ]);
  return (
    <div>
      <div className="product-detail-hero-container">
        <div style={{ width: "80%", backgroundColor: "#111111" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              color: "#F5C61F",
            }}
          >
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
            <p style={{ margin: "0px 10px" }}>Happy Review</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "white",
            }}
          >
            <p className="product-detail-title">DONEC COMMANDO</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "white",
              }}
            >
              <p style={{ margin: 0, color: "white" }}>Add To Cart</p>
              <LocalMall
                style={{
                  margin: "0px 10px",
                  color: "#FF3132",
                  fontSize: "28px",
                }}
              />
              <Button
                style={{ width: 150, height: 50, borderRadius: 0 }}
                className="main-button"
              >
                EXTERIOR
              </Button>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "80%",
            backgroundColor: "#202020",
            textAlign: "center",
          }}
        >
          <img
            src={customizeJeep}
            alt=""
            style={{ width: "70%", margin: "40px 0px" }}
          />
        </div>
        <div
          style={{
            width: "80%",
            backgroundColor: "#ffffff",
            textAlign: "center",
            padding: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 20,
            }}
          >
            <p
              style={{
                fontWeight: "800",
                fontSize: "44px",
                lineHeight: "52px",
                marginLeft: 20,
              }}
            >
              PRODUCT DETAIL
            </p>
            <div className="product-detail-header-divider"></div>
          </div>

          <div style={{ textAlign: "left", padding: "0px 20px" }}>
            <div className="about-vision-text">
              <p style={{ margin: "10px 10px" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Maecenas elementum felis at ligula ullamcorper. Vivamus vitae
                nisl libero. Pellentesque imperdiet ipsum ut sollicitudin
                efficitur. Nulla aliquet erat vel scelerisque efficitur. Etiam
                molestie neque lacus. Quisque quis ipsum at arcu faucibus
                rutrum. Quisque in dictum ipsum.
              </p>
            </div>
            <div className="about-vision-text" style={{ marginTop: 30 }}>
              <div className="product-detail-bullet"></div>
              <p style={{ margin: "-10px 10px" }}>
                Vestibulum tellus orci, aliquam a elit at, rhoncus dictum
                libero. Mauris cursus erat nec odio gravida finibus. Nullmauris
                id lacus vestibulum consequat consequat at augue.
              </p>
            </div>
            <div className="about-vision-text" style={{ marginTop: 30 }}>
              <div className="product-detail-bullet"></div>
              <p style={{ margin: "-10px 10px" }}>
                Vestibulum tellus orci, aliquam a elit at, rhoncus dictum
                libero. Mauris cursus erat nec odio gravida finibus. Nullmauris
                id lacus vestibulum consequat consequat at augue.
              </p>
            </div>
            <div className="about-vision-text" style={{ marginTop: 30 }}>
              <div className="product-detail-bullet"></div>
              <p style={{ margin: "-10px 10px" }}>
                Vestibulum tellus orci, aliquam a elit at, rhoncus dictum
                libero. Mauris cursus erat nec odio gravida finibus. Nullmauris
                id lacus vestibulum consequat consequat at augue.
              </p>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "20px 0px",
              }}
            >
              <div style={{ margin: "0px 10px" }}>
                <p style={{ fontSize: 18, margin: 0, marginBottom: -10 }}>
                  Vehicle Price
                </p>
                <p
                  style={{
                    fontSize: 32,
                    fontWeight: 800,
                    margin: 0,
                    color: "#FF3433",
                  }}
                >
                  $ 25,500
                </p>
              </div>

              <Button
                style={{
                  width: 230,
                  height: 50,
                  borderRadius: 0,
                  margin: "0px 5px",
                }}
                className="main-button"
              >
                Add To Cart
                <LocalMall
                  style={{
                    margin: "0px 10px",
                    color: "#ffffff",
                    fontSize: "28px",
                  }}
                />
              </Button>
              <Link
                to="/build-your-own"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button
                  style={{
                    width: 230,
                    height: 50,
                    borderRadius: 0,
                    margin: "0px 5px",
                  }}
                  className="main-button"
                >
                  Build Buy Your Own
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* SHOP NOW */}
      <div
        style={{
          textAlign: "center",
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="divider-hero"></div>
          <p
            style={{
              fontWeight: "800",
              fontSize: "44px",
              lineHeight: "52px",
              marginLeft: 20,
            }}
          >
            RELATED PRODUCTS
          </p>
          <div className="divider-hero"></div>
        </div>

        <Grid
          container
          spacing={2}
          style={{
            width: "90%",
            margin: "auto",
            marginTop: "50px",
          }}
        >
          {products.map((item) => {
            return (
              <Grid item md={4}>
                <div className="shop-card-container">
                  <div className="shop-card-image-container">
                    <img
                      src={
                        item.imageIndex == 1
                          ? jeep1
                          : item.imageIndex == 2
                          ? jeep2
                          : jeep3
                      }
                      alt=""
                      style={{ width: "80%" }}
                    />
                  </div>
                  <div className="shop-card-details">
                    <Link
                      to="/product-details"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <p className="shop-card-details-heading">{item.name}</p>
                    </Link>
                    <p className="shop-card-details-description">
                      Lorem ipsum dolor sit amet, consectetur elit. Sed
                      malesuada magna at leo scelerisque molestie.
                    </p>
                    <p className="shop-card-details-price">$ 100</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button className="main-button">
                        Build Buy Your Own
                      </Button>
                      <LocalMall
                        style={{
                          marginLeft: "10px",
                          color: "#FF3132",
                          fontSize: "36px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>

      <div className="mid-banner-2">
        <div>
          <p
            style={{
              fontWeight: "500",
              fontSize: "22px",
            }}
          >
            DRIVE BEYOND BOUNDARIES
          </p>
        </div>
        <p
          style={{
            fontWeight: "800",
            fontSize: "44px",
            lineHeight: "52px",
            textAlign: "center",
          }}
        >
          CUSTOMIZE DESIGN
        </p>
        <Button className="main-button">Customize Your Jeep</Button>
      </div>
    </div>
  );
};

export default ProductDetails;
