import React, { useState, useRef, useEffect } from "react";
import { Button, Divider, Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Delete } from "@mui/icons-material";

import homeHero from "../assets/svg/homePageHero.svg";
import bumper from "../assets/images/bumper.png";
import body from "../assets/images/body.png";

const Cart = () => {
  const [cart, setCart] = useState([
    { quantity: 1, price: 1500 },
    { quantity: 1, price: 3500 },
  ]);
  const [cartTotal, setCartTotal] = useState(0);

  const handleChangeCart = (action, index) => {
    const current = cart;
    if (action == "+") {
      const newCart = { quantity: cart[index].quantity + 1 };
      current[index] = newCart;
      setCart(current);
    } else if (action == "-") {
      const newCart = { quantity: cart[index].quantity - 1 };
      current[index] = newCart;
      setCart(current);
    }
  };

  useEffect(() => {
    let total = 0;
    cart.map((item) => {
      total = item.price * item.quantity;
    });
    setCartTotal(total);
  }, [cart]);

  return (
    <div>
      <div className="shoppage-hero-container">
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div className="cart-hero-container">
              <p className="cart-hero-title">CART</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <p className="cart-hero-description">HOME - CART</p>
                <div className="divider-hero"></div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} sm={0} className="hero-section-image">
            <img src={homeHero} alt="" style={{ width: "90%" }} />
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          width: "50%",
          height: 50,
          backgroundColor: "#111111",
          marginTop: -50,
          borderTopRightRadius: 50,
        }}
      ></div>

      <div className="cart-table-background">
        <table className="cart-table-container">
          <thead className="cart-table-head">
            <th className="cart-table-column cart-table-main-title">ITEMS</th>
            <th className="cart-table-column cart-mid-column cart-table-main-title">
              VEHICLE PARTS
            </th>
            <th className="cart-table-column cart-mid-column cart-table-main-title">
              PRICING
            </th>
            <th className="cart-table-column cart-table-main-title">
              QUANTITY
            </th>
          </thead>
          <tbody>
            <tr className="cart-table-row">
              <td className="cart-table-body-column cart-table-main-title">
                <img className="cart-image" src={bumper} />
              </td>
              <td className="cart-table-body-column cart-table-main-title">
                BUMPER
              </td>
              <td className="cart-table-body-column cart-table-description">
                <p className="cart-table-price">$1,500</p>
              </td>
              <td className="cart-table-body-column">
                <div className="cart-table-price-container">
                  <Button
                    className="cart-quantity-button"
                    onClick={(e) => handleChangeCart("+", 0)}
                  >
                    +
                  </Button>
                  <div className="cart-table-quantity">{cart[0].quantity}</div>
                  <Button
                    className="cart-quantity-button"
                    onClick={(e) => handleChangeCart("-", 0)}
                  >
                    -
                  </Button>
                  <Button className="main-button cart-table-button">
                    <Delete />
                  </Button>
                </div>
              </td>
            </tr>
            <tr className="cart-table-row">
              <td className="cart-table-body-column cart-table-main-title">
                <img className="cart-image" src={body} />
              </td>
              <td className="cart-table-body-column cart-table-main-title">
                BODY
              </td>
              <td className="cart-table-body-column cart-table-description">
                <p className="cart-table-price">$3,500</p>
              </td>
              <td className="cart-table-body-column">
                <div className="cart-table-price-container">
                  <Button
                    className="cart-quantity-button"
                    onClick={(e) => handleChangeCart("+", 1)}
                  >
                    +
                  </Button>
                  <div className="cart-table-quantity">1</div>
                  <Button
                    className="cart-quantity-button"
                    onClick={(e) => handleChangeCart("-", 1)}
                  >
                    -
                  </Button>
                  <Button className="main-button cart-table-button">
                    <Delete />
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
          <td
            colSpan={4}
            style={{
              width: "100%",
              backgroundColor: "#505050",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="cart-total">TOTAL PRICE</span>
              <span className="cart-table-price">{"$" + cartTotal}</span>
            </div>
          </td>
        </table>

        <div
          style={{
            margin: "auto",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Link to="/checkout" className="nav-links">
            <Button className="main-button">CHECKOUT</Button>
          </Link>
        </div>
      </div>

      <div className="mid-banner-2">
        <div>
          <p
            style={{
              fontWeight: "500",
              fontSize: "22px",
            }}
          >
            DRIVE BEYOND BOUNDARIES
          </p>
        </div>
        <p
          style={{
            fontWeight: "800",
            fontSize: "44px",
            lineHeight: "52px",
            textAlign: "center",
          }}
        >
          CUSTOMIZE DESIGN
        </p>
        <Link
          to="/build-your-own"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Button className="main-button">Customize Your Jeep</Button>
        </Link>
      </div>
    </div>
  );
};

export default Cart;
