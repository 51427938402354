import logo from "./logo.svg";
import React, { useRef, useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Shop from "./Pages/Shop";
import About from "./Pages/About";
import ProductDetails from "./Pages/ProductDetails";
import BuildYourOwn from "./Pages/BuildYourOwn";
import Contact from "./Pages/Contact";
import Cart from "./Pages/Cart";
import Wishlist from "./Pages/Wishlist";
import Checkout from "./Pages/Checkout";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import SigninPopup from "./Components/SigninPopup";

function App() {
  const [showPopup, setShowPopup] = useState(false);

  const handleShowPopup = () => {
    setShowPopup(!showPopup);
  };
  console.log(showPopup);
  return (
    <div>
      <Header handleShowPopup={handleShowPopup} />
      <SigninPopup open={showPopup} handleShowPopup={handleShowPopup} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/product-details" element={<ProductDetails />} />
        <Route path="/build-your-own" element={<BuildYourOwn />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/checkout" element={<Checkout />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
