import React, { useState, useEffect } from "react";
import { Button, Divider, Grid } from "@mui/material";

import { Link } from "react-router-dom";
import homeHero from "../assets/svg/homePageHero.svg";
import midBanner1 from "../assets/svg//mid-banner-1.svg";
import formImage from "../assets/images/form-image.png";

const Checkout = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="shoppage-hero-container">
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "white",
              }}
            >
              <p className="contact-hero-title">CHECKOUT</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <p
                  style={{
                    fontSize: "22px",
                    fontWeight: "500",
                  }}
                >
                  HOME - CHECKOUT
                </p>
                <div className="divider-hero"></div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} className="hero-section-image">
            <img src={homeHero} alt="" style={{ width: "90%" }} />
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          width: "50%",
          height: 50,
          backgroundColor: "#2b2b2b",
          marginTop: -50,
          borderTopRightRadius: 50,
        }}
      ></div>

      <div className="checkout-form-main-container">
        <div className="checkout-form-container">
          <Grid
            container
            spacing={2}
            style={{
              margin: "20px 0px",
            }}
          >
            <Grid md={6} sm={12} xs={12} item>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: 20,
                }}
              >
                <p className="contact-form-heading">CHECKOUT</p>
                <div className="form-divider1"></div>
              </div>
              <Grid container spacing={2}>
                <Grid md={6} sm={12} item style={{ width: "90%" }}>
                  <input
                    type="text"
                    placeholder="Full Name"
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      fontSize: 14,
                      padding: "3px 10px",
                      border: "none",
                      height: 40,
                    }}
                  />
                </Grid>
                <Grid md={6} sm={12} item style={{ width: "90%" }}>
                  <input
                    type="text"
                    placeholder="Phone"
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      fontSize: 14,
                      padding: "3px 10px",
                      border: "none",
                      height: 40,
                    }}
                  />
                </Grid>
                <Grid md={12} sm={12} item style={{ width: "90%" }}>
                  <input
                    type="email"
                    placeholder="Email"
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      fontSize: 14,
                      padding: "3px 10px",
                      border: "none",
                      height: 40,
                    }}
                  />
                </Grid>
                <Grid md={12} sm={12} item style={{ width: "90%" }}>
                  <input
                    type="text"
                    placeholder="Country"
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      fontSize: 14,
                      padding: "3px 10px",
                      border: "none",
                      height: 40,
                    }}
                  />
                </Grid>
                <Grid md={12} sm={12} item style={{ width: "90%" }}>
                  <input
                    type="text"
                    placeholder="Address"
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      fontSize: 14,
                      padding: "3px 10px",
                      border: "none",
                      height: 40,
                    }}
                  />
                </Grid>
                <Grid md={12} sm={12} item style={{ width: "90%" }}>
                  <input
                    type="text"
                    placeholder="Upfront"
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      fontSize: 14,
                      padding: "3px 10px",
                      border: "none",
                      height: 40,
                    }}
                  />
                </Grid>
                <Grid md={12} sm={12} item style={{ width: "90%" }}>
                  <textarea
                    type="text"
                    placeholder="Message"
                    className="contact-form-textarea"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid md={6} sm={12} xs={12} item className="form-image">
              <div
                style={{
                  width: "90%",
                  background: "#505050",
                  padding: "10px 0px",
                }}
              >
                <p className="checkout-form-heading">YOUR ORDER</p>
                <Divider style={{ color: "#ffffff", margin: "5px 0px" }} />
                <Grid container spacing={3} style={{ margin: "5px 0px" }}>
                  <Grid md={6} sm={6} xs={6} item>
                    <p style={{ color: "white", fontWeight: "800" }}>JEEP</p>
                  </Grid>
                  <Grid md={6} sm={6} xs={6} item>
                    <p style={{ color: "#ff3132" }}>$3,500</p>
                  </Grid>
                  <Grid md={6} sm={6} xs={6} item>
                    <p style={{ color: "white", fontWeight: "800" }}>BUMPER</p>
                  </Grid>
                  <Grid md={6} sm={6} xs={6} item>
                    <p style={{ color: "#ff3132" }}>$1,500</p>
                  </Grid>
                </Grid>
                <Divider style={{ color: "#ffffff", margin: "5px 0px" }} />
                <Grid container spacing={2} style={{ margin: "5px 0px" }}>
                  <Grid md={6} sm={6} xs={6} item>
                    <p className="checkout-form-main">SUBTOTAL</p>
                  </Grid>
                  <Grid md={6} sm={6} xs={6} item>
                    <p className="checkout-form-main-price">$5,000</p>
                  </Grid>
                  <Grid md={6} sm={6} xs={6} item>
                    <p className="checkout-form-main">TOTAL PRICE</p>
                  </Grid>
                  <Grid md={6} sm={6} xs={6} item>
                    <p className="checkout-form-main-price">$5,000</p>
                  </Grid>
                  <Grid md={6} sm={6} xs={6} item>
                    <p className="checkout-form-main">UPFRONT</p>
                  </Grid>
                  <Grid md={6} sm={6} xs={6} item>
                    <p className="checkout-form-main-price">$1,000</p>
                  </Grid>
                </Grid>
              </div>
              <Grid
                md={10}
                sm={10}
                item
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                  marginTop: 15,
                }}
              >
                <Button className="main-button">Submit Form</Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="mid-banner-2">
        <div>
          <p
            style={{
              fontWeight: "500",
              fontSize: "22px",
            }}
          >
            DRIVE BEYOND BOUNDARIES
          </p>
        </div>
        <p
          style={{
            fontWeight: "800",
            fontSize: "44px",
            lineHeight: "52px",
            textAlign: "center",
          }}
        >
          CUSTOMIZE DESIGN
        </p>
        <Link
          to="/build-your-own"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Button className="main-button">Customize Your Jeep</Button>
        </Link>
      </div>
    </div>
  );
};

export default Checkout;
