import React, { useState } from "react";
import { Modal } from "@mui/material";
import formImage from "../assets/images/form-image.png";
import { Button, Divider, Grid } from "@mui/material";
import { Close } from "@mui/icons-material";
import "../assets/css/signinPopup.css";

const SigninPopup = ({ open, handleShowPopup }) => {
  const [currentScreen, setCurrentScreen] = useState("signin");

  return (
    <Modal
      open={open}
      onClose={handleShowPopup}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="card-container ">
        <Grid container spacing={2}>
          <Grid md={7} sm={12} xs={12} item>
            <button
              onClick={handleShowPopup}
              style={{
                position: "absolute",
                left: 10,
                top: 10,
                color: "white",
                backgroundColor: "transparent",
              }}
            >
              <Close />
            </button>
            {currentScreen === "signin" ? (
              <div className="form-grid">
                <p className="contact-form-heading">WELCOME</p>
                <p style={{ color: "white", marginTop: -10 }}>
                  Sign into your account
                </p>
                <Grid container spacing={2}>
                  <Grid md={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="email"
                      placeholder="Email"
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                      }}
                    />
                  </Grid>
                  <Grid md={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="password"
                      placeholder="Password"
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                      }}
                    />
                  </Grid>
                  <Grid
                    md={6}
                    item
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <a
                      style={{
                        color: "#ffffff",
                        cursor: "pointer",
                        fontWeight: "400",
                      }}
                      onClick={(e) => setCurrentScreen("forget")}
                    >
                      Forgot Password?
                    </a>
                  </Grid>
                  <Grid
                    md={6}
                    item
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button className="main-button">Submit Form</Button>
                  </Grid>
                </Grid>

                <div
                  className="divider-grid"
                  style={{ marginTop: 50, marginBottom: 10, color: "#ffffff" }}
                ></div>

                <p style={{ color: "white" }}>
                  Dont have an account?{" "}
                  <a
                    style={{
                      color: "#ffffff",
                      cursor: "pointer",
                      fontWeight: "800",
                    }}
                    onClick={(e) => setCurrentScreen("signup")}
                  >
                    SignUp
                  </a>
                </p>
              </div>
            ) : currentScreen === "signup" ? (
              <div className="form-grid">
                <p className="contact-form-heading">WELCOME</p>
                <p style={{ color: "white", marginTop: -10 }}>
                  Signup for your free account
                </p>
                <Grid container spacing={2}>
                  <Grid md={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="text"
                      placeholder="Full Name"
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                      }}
                    />
                  </Grid>
                  <Grid md={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="text"
                      placeholder="Phone No."
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                      }}
                    />
                  </Grid>
                  <Grid md={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="email"
                      placeholder="Email"
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                      }}
                    />
                  </Grid>
                  <Grid md={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="password"
                      placeholder="Password"
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                      }}
                    />
                  </Grid>
                  <Grid
                    md={12}
                    xs={12}
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button className="main-button">Sign Up</Button>
                  </Grid>
                </Grid>

                <div
                  className="divider-grid"
                  style={{ marginTop: 30, marginBottom: 10, color: "#ffffff" }}
                ></div>

                <p style={{ color: "white" }}>
                  Already have an account?{" "}
                  <a
                    style={{
                      color: "#ffffff",
                      cursor: "pointer",
                      fontWeight: "800",
                    }}
                    onClick={(e) => setCurrentScreen("signin")}
                  >
                    SignIn
                  </a>
                </p>
              </div>
            ) : (
              <div className="form-grid">
                <p className="contact-form-heading">FORGOT PASSWORD</p>
                <p style={{ color: "white", marginTop: -10 }}>
                  Input your email to reset password
                </p>
                <Grid container spacing={2}>
                  <Grid md={12} xs={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="email"
                      placeholder="Email"
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                      }}
                    />
                  </Grid>
                  <Grid
                    md={12}
                    xs={12}
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button className="main-button">Forgot</Button>
                  </Grid>
                </Grid>

                <div
                  className="divider-grid"
                  style={{ marginTop: 50, marginBottom: 10, color: "#ffffff" }}
                ></div>

                <p style={{ color: "white" }}>
                  Back to Signint?{" "}
                  <a
                    style={{
                      color: "#ffffff",
                      cursor: "pointer",
                      fontWeight: "800",
                    }}
                    onClick={(e) => setCurrentScreen("signin")}
                  >
                    Signin
                  </a>
                </p>
              </div>
            )}
          </Grid>
          <Grid md={5} sm={0} item className="form-image">
            <img
              src={formImage}
              className="image-grid"
              alt="jeep"
              width={"70%"}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default SigninPopup;
