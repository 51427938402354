import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import logo from "../logo.svg";
// import logo from "../assets/svg/Logo-Descriptive-White.svg";
import { Button } from "@mui/material";
import logo from "../assets/svg/logo_black.svg";
import "../assets/css/Navbar.css";
import { Search, Favorite, LocalMall, Person } from "@mui/icons-material";

const Header = ({ handleShowPopup }) => {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const location = useLocation();

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);
  return (
    // <div
    //   style={{
    //     display: "flex",
    //     alignItems: "center",
    //     width: "100%",
    //     height: 90,
    //     justifyContent: "space-around",
    //     backgroundColor: "#00A6E0",
    //     zIndex: -99,
    //   }}
    // >
    //   <Link to="/">
    //     <img src={logo} alt="logo" className="logo" />
    //   </Link>
    //   <div style={{ right: 0 }}>
    //     <Link to="/" className="navbar-link">
    //       Home
    //     </Link>
    //     <Link to="/news-and-articles" className="navbar-link">
    //       New & Atricles
    //     </Link>
    //     <Link to="/career" className="navbar-link">
    //       Career
    //     </Link>
    //   </div>
    // </div>
    <nav className="navbar" style={{ width: "100%" }}>
      <div className="navbar-container">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={logo} alt="logo" className="logo-header" />
          {/* <i class="fab fa-typo3" /> */}
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/shop" className="nav-links" onClick={closeMobileMenu}>
              Shop
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
              Contact
            </Link>
          </li>
          <li
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="nav-item nav-mobile-hide"
          >
            <div style={{ margin: "0rem 1rem" }}>
              <input
                type="text"
                placeholder="Search"
                style={{
                  width: "200px",
                  height: "40px",
                  backgroundColor: "#FFFFFF",
                  fontSize: 14,
                  padding: "3px 10px",
                  border: "none",
                  marginRight: "-30px",
                }}
              />
              <Search />
            </div>
          </li>
          <li className="nav-item nav-mobile-hide">
            <Link to="/cart" className="nav-links" onClick={closeMobileMenu}>
              <LocalMall />
            </Link>
          </li>
          <li className="nav-item nav-mobile-hide">
            <Link
              to="/wishlist"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              <Favorite />
            </Link>
          </li>
          <li className="nav-item nav-mobile-hide">
            <Button
              className="nav-links-popup"
              onClick={(e) => handleShowPopup()}
            >
              <Person />
            </Button>
          </li>
          <li className="nav-item nav-mobile-show">
            <Link
              to="/cart"
              className="nav-links"
              onClick={(e) => {
                closeMobileMenu();
              }}
            >
              Cart
            </Link>
          </li>
          <li className="nav-item nav-mobile-show">
            <Link
              to="/wishlist"
              className="nav-links"
              onClick={(e) => {
                closeMobileMenu();
              }}
            >
              Wishlist
            </Link>
          </li>
          <li className="nav-item nav-mobile-show">
            <Button
              className="nav-links-popup"
              onClick={(e) => {
                closeMobileMenu();
                handleShowPopup();
              }}
            >
              Signin
            </Button>
          </li>
        </ul>
        {/* {button && <Button buttonStyle="btn--outline">SIGN UP</Button>} */}
      </div>
    </nav>
  );
};

export default Header;
