import React, { useState, useEffect, useRef } from "react";
import { Button, Divider, Grid } from "@mui/material";
import {
  Phone,
  Mail,
  LocationOn,
  Public,
  LinkRounded,
  FacebookRounded,
  LinkedIn,
} from "@mui/icons-material";
import Slider from "@mui/material/Slider";

import { Link } from "react-router-dom";
// import Banner from "../assets/svg/cover phone.svg";
import homeHero from "../assets/svg/homePageHero.svg";
import midBanner1 from "../assets/svg//mid-banner-1.svg";
import formImage from "../assets/images/form-image.png";
import jeep1 from "../assets/images/demoProduct1.png";
import jeep2 from "../assets/images/demoProduct2.png";
import jeep3 from "../assets/images/demoProduct3.png";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="shoppage-hero-container">
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "white",
              }}
            >
              <p className="contact-hero-title">CONTACT US</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <p
                  style={{
                    fontSize: "22px",
                    fontWeight: "500",
                  }}
                >
                  HOME - CONTACT
                </p>
                <div className="divider-hero"></div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} className="hero-section-image">
            <img src={homeHero} alt="" style={{ width: "90%" }} />
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          width: "50%",
          height: 50,
          backgroundColor: "#2b2b2b",
          marginTop: -50,
          borderTopRightRadius: 50,
        }}
      ></div>

      <div className="contact-form-main-container">
        <div className="contact-form-container">
          <Grid
            container
            spacing={2}
            style={{
              margin: "20px 0px",
            }}
          >
            <Grid md={7} sm={12} item>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: 20,
                }}
              >
                <p className="contact-form-heading">CONTACT FORM</p>
                <div className="form-divider1"></div>
              </div>
              <Grid container spacing={2}>
                <Grid md={6} sm={12} item style={{ width: "90%" }}>
                  <input
                    type="text"
                    placeholder="First Name"
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      fontSize: 14,
                      padding: "3px 10px",
                      border: "none",
                      height: 40,
                    }}
                  />
                </Grid>
                <Grid md={6} sm={12} item style={{ width: "90%" }}>
                  <input
                    type="text"
                    placeholder="Last Name"
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      fontSize: 14,
                      padding: "3px 10px",
                      border: "none",
                      height: 40,
                    }}
                  />
                </Grid>
                <Grid md={6} sm={12} item style={{ width: "90%" }}>
                  <input
                    type="text"
                    placeholder="Phone"
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      fontSize: 14,
                      padding: "3px 10px",
                      border: "none",
                      height: 40,
                    }}
                  />
                </Grid>
                <Grid md={6} sm={12} item style={{ width: "90%" }}>
                  <input
                    type="email"
                    placeholder="Email"
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      fontSize: 14,
                      padding: "3px 10px",
                      border: "none",
                      height: 40,
                    }}
                  />
                </Grid>
                <Grid md={12} sm={12} item style={{ width: "90%" }}>
                  <textarea
                    type="text"
                    placeholder="Message"
                    className="contact-form-textarea"
                  />
                </Grid>
                <Grid md={8} sm={12} item>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input type="radio" />
                    <p
                      style={{
                        fontSize: 12,
                        color: "white",
                        marginTop: 12,
                        marginLeft: 10,
                      }}
                    >
                      I Accept Privacy Policy
                    </p>
                  </div>
                </Grid>
                <Grid
                  md={4}
                  sm={12}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button className="main-button">Submit Form</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid md={5} sm={12} item className="form-image">
              <img src={formImage} alt="jeep" width={"70%"} />
            </Grid>
          </Grid>
        </div>
      </div>

      <div>
        <Grid container spacing={2} className="mid-banner-1">
          <Grid
            item
            md={5}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={midBanner1} alt="" style={{ width: "100%" }} />
          </Grid>
          <Grid
            item
            md={7}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="contact-address-container">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="contact-form-heading">GET IN TOUCH</p>
                <div className="contact-driver1"></div>
              </div>
              <div className="contact-address-text">
                <Phone />
                <p style={{ margin: "10px 10px" }}>612-444-2262</p>
              </div>
              <div className="contact-address-text">
                <Mail />
                <p style={{ margin: "10px 10px" }}>my3djeep@gmail.com</p>
              </div>
              <div className="contact-address-text">
                <Phone />
                <p style={{ margin: "10px 10px" }}>www.my3djeep.com</p>
              </div>
              <div className="contact-address-text">
                <LocationOn />
                <p style={{ margin: "10px 10px" }}>Minneapolis, MN</p>
              </div>
              <div style={{ display: "flex" }}>
                <div className="contact-address-text">
                  <LinkedIn />
                  <p style={{ margin: "10px 10px" }}>@my3djeep</p>
                </div>
                <div className="contact-address-text">
                  <FacebookRounded />
                  <p style={{ margin: "10px 10px" }}>@my3djeep</p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="mid-banner-2">
        <div>
          <p
            style={{
              fontWeight: "500",
              fontSize: "22px",
            }}
          >
            DRIVE BEYOND BOUNDARIES
          </p>
        </div>
        <p
          style={{
            fontWeight: "800",
            fontSize: "44px",
            lineHeight: "52px",
            textAlign: "center",
          }}
        >
          CUSTOMIZE DESIGN
        </p>
        <Link
          to="/build-your-own"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Button className="main-button">Customize Your Jeep</Button>
        </Link>
      </div>
    </div>
  );
};

export default Contact;
