import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/svg/logo_black.svg";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import { Divider } from "@mui/material";
import "../assets/css/Footer.css";
import { Facebook, LinkedIn, Twitter } from "@mui/icons-material";
import footerIcon from "../assets/images/footer_icon.svg";

const Footer = () => {
  const [email, setEmail] = useState("");
  return (
    <div className="footer">
      <div className="footer-grid">
        <Grid container spacing={3}>
          <Grid item md={1} className="empty-section"></Grid>
          <Grid item md={5} sm={12} className="footer-logo">
            <img src={logo} alt="logo" className="logo" />
          </Grid>
          <Grid item md={2} sm={12} className="footer-text">
            <p className="footer-section-header">Quick Links</p>
            <Link className="footer-link" to={"/"}>
              <p className="footer-link-text">Home</p>
            </Link>
            <Link className="footer-link" to={"/shop"}>
              <p className="footer-link-text">Shop</p>
            </Link>
            <Link className="footer-link" to={"/about"}>
              <p className="footer-link-text">About</p>
            </Link>
            <Link className="footer-link" to={"/contact"}>
              <p className="footer-link-text">Contact </p>
            </Link>
          </Grid>
          <Grid item md={2} sm={12} className="footer-text">
            <p className="footer-section-header">Shop Products</p>
            <Link className="footer-link">
              <p className="footer-link-text">Lorem ipsum dolor</p>
            </Link>
            <Link className="footer-link">
              <p className="footer-link-text">Lorem ipsum dolor</p>
            </Link>
            <Link className="footer-link">
              <p className="footer-link-text">Lorem ipsum dolor</p>
            </Link>
            <Link className="footer-link">
              <p className="footer-link-text">Lorem ipsum dolor </p>
            </Link>
          </Grid>
          <Grid item md={2} sm={12} className="footer-text">
            <p className="footer-section-header">Get in Touch</p>
            <Link className="footer-link">
              <p className="footer-link-text">612-444-2262</p>
            </Link>
            <Link className="footer-link">
              <p className="footer-link-text">my3djeep@gmail.com</p>
            </Link>
            <Link className="footer-link">
              <p className="footer-link-text">Minneapolis, MN</p>
            </Link>
          </Grid>
        </Grid>
      </div>
      <Divider className="footer-divider">
        <button
          style={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            border: "1px solid black",
            margin: "0px 5px",
          }}
        >
          <LinkedIn style={{ color: "#FF3132" }} />
        </button>
        <button
          style={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            border: "1px solid black",
            margin: "0px 5px",
          }}
        >
          <Facebook style={{ color: "#FF3132" }} />
        </button>
        <button
          style={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            border: "1px solid black",
            margin: "0px 5px",
          }}
        >
          <Twitter style={{ color: "#FF3132" }} />
        </button>
      </Divider>
      <div className="footer-copyright-section">
        <p>
          Copyright 2024 @ My 3d Jeep, Design and Developed By{" "}
          <a href="https://maazsami.com/" target="_parent">
            <img
              src={footerIcon}
              style={{
                width: 35,
                height: 35,
              }}
            />
          </a>
        </p>
        <p>Privacy Policy</p>
      </div>
    </div>
  );
};

export default Footer;
