import React, { useState, useEffect, useRef } from "react";
import { Button, Divider, Grid } from "@mui/material";
import {
  LocalMall,
  ArrowBack,
  ArrowForward,
  Search,
} from "@mui/icons-material";
import Slider from "@mui/material/Slider";
import { Link } from "react-router-dom";

// import Banner from "../assets/svg/cover phone.svg";
import homeHero from "../assets/svg/homePageHero.svg";
import midBanner1 from "../assets/svg//mid-banner-1.svg";
import jeep1 from "../assets/images/demoProduct1.png";
import jeep2 from "../assets/images/demoProduct2.png";
import jeep3 from "../assets/images/demoProduct3.png";

const Shop = () => {
  const [value, setValue] = React.useState([20000, 37000]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [products, setProducts] = useState([
    { name: "DONEC COMMANDO", imageIndex: 1 },
    { name: "DONEC COMMANDO", imageIndex: 2 },
    { name: "DONEC COMMANDO", imageIndex: 3 },
    { name: "DONEC COMMANDO", imageIndex: 3 },
    { name: "DONEC COMMANDO", imageIndex: 1 },
    { name: "DONEC COMMANDO", imageIndex: 2 },
    { name: "DONEC COMMANDO", imageIndex: 1 },
    { name: "DONEC COMMANDO", imageIndex: 3 },
    { name: "DONEC COMMANDO", imageIndex: 2 },
    { name: "DONEC COMMANDO", imageIndex: 3 },
    { name: "DONEC COMMANDO", imageIndex: 1 },
    { name: "DONEC COMMANDO", imageIndex: 2 },
    { name: "DONEC COMMANDO", imageIndex: 1 },
    { name: "DONEC COMMANDO", imageIndex: 3 },
    { name: "DONEC COMMANDO", imageIndex: 1 },
    { name: "DONEC COMMANDO", imageIndex: 2 },
    { name: "DONEC COMMANDO", imageIndex: 1 },
    { name: "DONEC COMMANDO", imageIndex: 3 },
    { name: "DONEC COMMANDO", imageIndex: 2 },
    { name: "DONEC COMMANDO", imageIndex: 3 },
    { name: "DONEC COMMANDO", imageIndex: 1 },
    { name: "DONEC COMMANDO", imageIndex: 2 },
    { name: "DONEC COMMANDO", imageIndex: 1 },
  ]);
  const [totalPages, settotalPages] = React.useState(0);
  const [price, setPrice] = React.useState([
    {
      component: "Body",
      price: 49000,
    },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function valuetext(value) {
    return `${value}°C`;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className="shoppage-hero-container">
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "white",
              }}
            >
              <p
                style={{
                  fontWeight: "800",
                  fontSize: "44px",
                }}
              >
                SHOP NOW
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <p
                  style={{
                    fontSize: "22px",
                    fontWeight: "500",
                  }}
                >
                  HOME - SHOP
                </p>
                <div className="divider-hero"></div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} className="hero-section-image">
            <img src={homeHero} alt="" style={{ width: "90%" }} />
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          width: "50%",
          height: 50,
          backgroundColor: "white",
          marginTop: -50,
          borderTopRightRadius: 50,
        }}
      ></div>

      <div className="shop-filter-container">
        <Grid
          container
          spacing={2}
          style={{
            margin: "20px 0px",
          }}
        >
          <Grid md={6} xs={12} item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: 20,
              }}
            >
              <p className="shop-filter-heading">PRODUCTS</p>
              <div className="filter-divider1"></div>
            </div>
            <Grid container spacing={2}>
              <Grid md={4} item>
                <p className="shop-filter-options">LOREM IPSUM</p>
              </Grid>
              <Grid md={4} item>
                <p className="shop-filter-options">LOREM IPSUM</p>
              </Grid>
              <Grid md={4} item>
                <p className="shop-filter-options">LOREM IPSUM</p>
              </Grid>
              <Grid md={4} item>
                <p className="shop-filter-options">LOREM IPSUM</p>
              </Grid>
              <Grid md={4} item>
                <p className="shop-filter-options">LOREM IPSUM</p>
              </Grid>
              <Grid md={4} item>
                <p className="shop-filter-options">LOREM IPSUM</p>
              </Grid>
              <Grid md={4} item>
                <p className="shop-filter-options">LOREM IPSUM</p>
              </Grid>
              <Grid md={4} item>
                <p className="shop-filter-options">LOREM IPSUM</p>
              </Grid>
              <Grid md={4} item>
                <p className="shop-filter-options">LOREM IPSUM</p>
              </Grid>
              <Grid md={4} item>
                <p className="shop-filter-options">LOREM IPSUM</p>
              </Grid>
              <Grid md={4} item>
                <p className="shop-filter-options">LOREM IPSUM</p>
              </Grid>
              <Grid md={4} item>
                <p className="shop-filter-options">LOREM IPSUM</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid md={6} xs={12} item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "90%",
              }}
            >
              <div
                style={{
                  height: "60%",
                  paddingRight: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 20,
                  }}
                >
                  <p className="shop-filter-heading">PRICE RANGE</p>
                  <div className="filter-divider2"></div>
                </div>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={value}
                  style={{ color: "#ff3132" }}
                  onChange={handleChange}
                  valueLabelDisplay="on"
                  getAriaValueText={valuetext}
                  max={40000}
                  min={10000}
                />
              </div>
              <div
                style={{
                  height: "40%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingRight: 20,
                  }}
                >
                  <p className="shop-filter-heading">TAGS</p>
                  <div className="filter-divider3"></div>
                </div>
                <Grid
                  container
                  spacing={2}
                  style={{
                    margin: "0px 0px",
                    width: "100%",
                  }}
                >
                  <Grid xs={4}>
                    <Button
                      style={{ width: "80%", borderRadius: 0 }}
                      className="main-button"
                    >
                      NEW
                    </Button>
                  </Grid>
                  <Grid xs={4}>
                    <Button
                      style={{ width: "80%", borderRadius: 0 }}
                      className="main-button"
                    >
                      TOP
                    </Button>
                  </Grid>
                  <Grid xs={4}>
                    <Button
                      style={{ width: "80%", borderRadius: 0 }}
                      className="main-button"
                    >
                      RECENT
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <input
            type="text"
            placeholder="Search Any Product..."
            style={{
              width: "90%",
              height: "40px",
              backgroundColor: "#292929",
              fontSize: 14,
              padding: "3px 20px",
              border: "none",
              marginRight: "-30px",
            }}
          />
          <Search style={{ color: "white" }} />
        </div>
      </div>

      {/* SHOP NOW */}
      <div
        style={{
          textAlign: "center",
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <p style={{ fontWeight: "800", fontSize: "44px", lineHeight: "52px" }}>
          SHOP NOW
        </p>
        <p style={{ width: "40%", textAlign: "center", margin: "auto" }}>
          Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at
          leo scelerisque molestie. Vivamus sed sodales odio, in faucibus
          sapien.
        </p>

        <Grid
          container
          spacing={2}
          style={{
            width: "90%",
            margin: "auto",
            marginTop: "50px",
          }}
        >
          {products
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item) => {
              return (
                <Grid item md={4}>
                  <div className="shop-card-container">
                    <div className="shop-card-image-container">
                      <img
                        src={
                          item.imageIndex == 1
                            ? jeep1
                            : item.imageIndex == 2
                            ? jeep2
                            : jeep3
                        }
                        alt=""
                        style={{ width: "80%" }}
                      />
                    </div>
                    <div className="shop-card-details">
                      <Link
                        to="/product-details"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <p className="shop-card-details-heading">{item.name}</p>
                      </Link>
                      <p className="shop-card-details-description">
                        Lorem ipsum dolor sit amet, consectetur elit. Sed
                        malesuada magna at leo scelerisque molestie.
                      </p>
                      <p className="shop-card-details-price">$ 100</p>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Link
                          to="/build-your-own"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <Button className="main-button">
                            Build Buy Your Own
                          </Button>
                        </Link>
                        <LocalMall
                          style={{
                            marginLeft: "10px",
                            color: "#FF3132",
                            fontSize: "36px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
        </Grid>

        <div style={{ marginTop: 40 }}>
          <Button
            className="main-button"
            disabled={page == 0}
            onClick={(e) => setPage(page - 1)}
          >
            <ArrowBack />
          </Button>
          <Button
            style={{
              backgroundColor: page == 0 ? "#ff3132" : "#f0f0f0",
              color: page == 0 ? "white" : "black",
              margin: "0px 5px",
              borderRadius: 0,
            }}
            onClick={(e) => setPage(0)}
          >
            01
          </Button>
          <Button
            style={{
              backgroundColor: page == 1 ? "#ff3132" : "#f0f0f0",
              color: page == 1 ? "white" : "black",
              margin: "0px 5px",
              borderRadius: 0,
            }}
            onClick={(e) => setPage(1)}
          >
            02
          </Button>
          <Button
            style={{
              backgroundColor: page == 2 ? "#ff3132" : "#f0f0f0",
              color: page == 2 ? "white" : "black",
              margin: "0px 5px",
              borderRadius: 0,
            }}
            onClick={(e) => setPage(2)}
          >
            03
          </Button>
          <Button
            style={{
              backgroundColor: page == 3 ? "#ff3132" : "#f0f0f0",
              color: page == 3 ? "white" : "black",
              margin: "0px 5px",
              borderRadius: 0,
            }}
            onClick={(e) => setPage(3)}
          >
            04
          </Button>
          <Button
            className="main-button"
            disabled={page == 3}
            onClick={(e) => setPage(page + 1)}
          >
            <ArrowForward />
          </Button>
        </div>
      </div>

      <div>
        <Grid container spacing={2} className="mid-banner-1">
          <Grid
            item
            md={7}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={midBanner1} alt="" style={{ width: "100%" }} />
          </Grid>
          <Grid
            item
            md={5}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "black",
              }}
            >
              <p className="customize-section-sub-heading">
                Elevate Your Drive, Customize in 3D
              </p>
              <p className="customize-section-heading">
                3D Customize Your Jeep Adventure!
              </p>
              <Link
                to="/build-your-own"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button className="main-button">Customize Your Jeep</Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="mid-banner-2">
        <div>
          <p
            style={{
              fontWeight: "500",
              fontSize: "22px",
            }}
          >
            DRIVE BEYOND BOUNDARIES
          </p>
        </div>
        <p
          style={{
            fontWeight: "800",
            fontSize: "44px",
            lineHeight: "52px",
            textAlign: "center",
          }}
        >
          CUSTOMIZE DESIGN
        </p>
        <Link
          to="/build-your-own"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Button className="main-button">Customize Your Jeep</Button>
        </Link>
      </div>
    </div>
  );
};

export default Shop;
