import React, { useState, useEffect, useRef } from "react";
import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
// import Banner from "../assets/svg/cover phone.svg";
import homeHero from "../assets/svg/homePageHero.svg";
import midBanner1 from "../assets/svg//mid-banner-1.svg";
import { LocalMall, ArrowBack, ArrowForward } from "@mui/icons-material";
import jeep1 from "../assets/images/demoProduct1.png";
import jeep2 from "../assets/images/demoProduct2.png";
import jeep3 from "../assets/images/demoProduct3.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import ModelRenderer from "../Components/3DModel";
import bumper from "../assets/images/bumper.png";
import body from "../assets/images/body.png";
import rims from "../assets/images/rims.png";
import tyre from "../assets/images/tyre.png";
import "../assets/css/image-crousel.css";

const Home = () => {
  const [grillColor, setGrillColor] = useState(null);
  const [bumperColor, setBumperColor] = useState(null);
  const [grillIndex, setGrillIndex] = useState(-1);
  const [bumperIndex, setBumperIndex] = useState(-1);
  const [category, setCategory] = useState("exterior");
  const [price, setPrice] = React.useState([
    {
      component: "Body",
      price: 49000,
    },
  ]);
  const [totalPrice, setTotalPrice] = React.useState(49000);

  const handlePriceChange = (value) => {
    console.log(value);
    const newArray = price.filter((item) => {
      return item.component !== value.component;
    });

    newArray.push(value);
    setPrice(newArray);
    console.log(newArray);

    let totalValue = 0;
    newArray.map((item) => {
      totalValue = totalValue + item.price;
    });
    setTotalPrice(totalValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const boxRef = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  const btnpressprev = () => {
    if (boxRef.current) {
      const width = boxRef.current.clientWidth;
      setScrollLeft(scrollLeft - width);
    }
  };

  const btnpressnext = () => {
    if (boxRef.current) {
      const width = boxRef.current.clientWidth;
      console.log(width);
      setScrollLeft(scrollLeft + width);
    }
  };

  return (
    <div>
      <div className="homepage-hero-container">
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "white",
              }}
            >
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "22px",
                }}
              >
                WELCOME TO MY 3D JEEP
              </p>
              <p
                style={{
                  fontWeight: "800",
                  fontSize: "23px",
                }}
              >
                Elevate your Jeep adventure by customizing & ordering your
                MY3DJEEP today!
              </p>
              <p
                style={{
                  fontSize: "18px",
                }}
              >
                Our goal is to fuel your passion for Jeep Aventure. Get started
                by customizing your Jeep today!
              </p>
              <Link
                to="/build-your-own"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    padding: "5px 10px",
                    fontSize: "13px",
                    fontWeight: "700",
                    borderRadius: 0,
                  }}
                >
                  Customize Your Jeep
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item md={6} className="hero-section-image">
            <img src={homeHero} alt="" style={{ width: "90%" }} />
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          width: "50%",
          height: 50,
          backgroundColor: "black",
          marginTop: -50,
          borderTopRightRadius: 50,
        }}
      ></div>

      <div>
        <Grid container spacing={2} className="featured-models">
          <Grid
            item
            md={7}
            sm={12}
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <div className="product-carousel">
              <div
                className="product-container"
                ref={boxRef}
                style={{ scrollLeft: scrollLeft }}
              >
                <div className="featured-card-container">
                  <div className="featured-card-image-container">
                    <img src={jeep1} alt="" style={{ width: "90%" }} />
                  </div>
                  <div className="featured-link-arrow-button">
                    <Button>
                      <ArrowOutwardIcon style={{ margin: 0 }} color="#fff" />
                    </Button>
                  </div>
                  <Link to="/product-details">
                    <Button className="featured-link-button">
                      1 Donec commodo
                    </Button>
                  </Link>
                </div>
                <div className="featured-card-container">
                  <div className="featured-card-image-container">
                    <img src={jeep1} alt="" style={{ width: "90%" }} />
                  </div>
                  <div className="featured-link-arrow-button">
                    <Button>
                      <ArrowOutwardIcon style={{ margin: 0 }} color="#fff" />
                    </Button>
                  </div>
                  <Link to="/product-details">
                    <Button className="featured-link-button">
                      2 Donec commodo
                    </Button>
                  </Link>
                </div>
                <div className="featured-card-container">
                  <div className="featured-card-image-container">
                    <img src={jeep1} alt="" style={{ width: "90%" }} />
                  </div>
                  <div className="featured-link-arrow-button">
                    <Button>
                      <ArrowOutwardIcon style={{ margin: 0 }} color="#fff" />
                    </Button>
                  </div>
                  <Link to="/product-details">
                    <Button className="featured-link-button">
                      3 Donec commodo
                    </Button>
                  </Link>
                </div>
                <div className="featured-card-container">
                  <div className="featured-card-image-container">
                    <img src={jeep2} alt="" style={{ width: "90%" }} />
                  </div>
                  <div className="featured-link-arrow-button">
                    <Button>
                      <ArrowOutwardIcon style={{ margin: 0 }} color="#fff" />
                    </Button>
                  </div>
                  <Link to="/product-details">
                    <Button className="featured-link-button">
                      4 Donec commodo
                    </Button>
                  </Link>
                </div>
                <div className="featured-card-container">
                  <div className="featured-card-image-container">
                    <img src={jeep2} alt="" style={{ width: "90%" }} />
                  </div>
                  <div className="featured-link-arrow-button">
                    <Button>
                      <ArrowOutwardIcon style={{ margin: 0 }} color="#fff" />
                    </Button>
                  </div>
                  <Link to="/product-details">
                    <Button className="featured-link-button">
                      5 Donec commodo
                    </Button>
                  </Link>
                </div>
                <div className="featured-card-container">
                  <div className="featured-card-image-container">
                    <img src={jeep2} alt="" style={{ width: "90%" }} />
                  </div>
                  <div className="featured-link-arrow-button">
                    <Button>
                      <ArrowOutwardIcon style={{ margin: 0 }} color="#fff" />
                    </Button>
                  </div>
                  <Link to="/product-details">
                    <Button className="featured-link-button">
                      6 Donec commodo
                    </Button>
                  </Link>
                </div>
              </div>
            </div>

            {/* <div className="card-slide-container">
              <div className="card-slide">
                {slideData.map((card, index) => (
                  <div
                    key={index}
                    className={`card ${index === currentCard ? "active" : ""}`}
                  >
                    <h2>{card.title}</h2>
                    <p>{card.content}</p>
                  </div>
                ))}
              </div>
              <button onClick={prevCard}>Previous</button>
              <button onClick={nextCard}>Next</button>
            </div> */}
          </Grid>
          <Grid
            item
            md={5}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "white",
              }}
            >
              <p className="featured-models-heading">FEATURED MODELS</p>
              <p className="featured-models-description">
                Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada
                magna at leo scelerisque molestie. Vivamus sed sodales odio, in
                faucibus sapien.
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <button
                  onClick={btnpressprev}
                  className="featured-scroll-arrow-button"
                >
                  <ArrowBack />
                </button>
                <button
                  onClick={btnpressnext}
                  className="featured-scroll-arrow-button"
                >
                  <ArrowForward />
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="build-your-own-container">
        <p className="build-your-own-title">BUILD YOUR OWN</p>
        <Grid
          container
          spacing={3}
          style={{
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingTop: "50px",
          }}
        >
          <Grid md={7} sm={12} className="build-card-vehicle-container">
            <ModelRenderer
              grillIndex={grillIndex}
              grillColor={grillColor}
              bumperColor={bumperColor}
              bumperIndex={bumperIndex}
            />
          </Grid>
          <Grid md={5} sm={12} className="build-card-options-container">
            <Grid
              container
              spacing={2}
              style={{
                margin: "0px 20px",
                width: "100%",
              }}
            >
              <Grid xs={4}>
                <Button
                  style={{ width: "80%", borderRadius: 0 }}
                  className="main-button"
                >
                  FRONT
                </Button>
              </Grid>
              <Grid xs={4}>
                <Button
                  style={{ width: "80%", borderRadius: 0 }}
                  className="main-button"
                >
                  BACK
                </Button>
              </Grid>
              <Grid xs={4}>
                <Button
                  style={{ width: "80%", borderRadius: 0 }}
                  className="main-button"
                >
                  TOP
                </Button>
              </Grid>
              <Grid xs={6} style={{ marginTop: 20 }}>
                <div className="build-card">
                  <div className="build-card-image">
                    <img src={bumper} />
                  </div>
                  <p className="build-card-text">BUMPER</p>
                </div>
              </Grid>
              <Grid xs={6} style={{ marginTop: 20 }}>
                <div className="build-card">
                  <div className="build-card-image">
                    <img src={body} />
                  </div>
                  <p className="build-card-text">BODY</p>
                </div>
              </Grid>
              <Grid xs={6} style={{ marginTop: 20 }}>
                <div className="build-card">
                  <div className="build-card-image">
                    <img src={rims} />
                  </div>
                  <p className="build-card-text">RIMS</p>
                </div>
              </Grid>
              <Grid xs={6} style={{ marginTop: 20 }}>
                <div className="build-card">
                  <div className="build-card-image">
                    <img src={tyre} />
                  </div>
                  <p className="build-card-text">TYRE</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div>
        <Grid container spacing={2} className="mid-banner-1">
          <Grid
            item
            md={7}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={midBanner1} alt="" style={{ width: "100%" }} />
          </Grid>
          <Grid
            item
            md={5}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "black",
              }}
            >
              <p className="customize-section-sub-heading">
                Elevate Your Drive, Customize in 3D
              </p>
              <p className="customize-section-heading">
                3D Customize Your Jeep Adventure!
              </p>
              <Link
                to="/build-your-own"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button className="main-button">Customize Your Jeep</Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>

      {/* SHOP NOW */}
      <div
        style={{
          textAlign: "center",
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <p style={{ fontWeight: "800", fontSize: "44px", lineHeight: "52px" }}>
          SHOP NOW
        </p>
        <p style={{ width: "40%", textAlign: "center", margin: "auto" }}>
          Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada magna at
          leo scelerisque molestie. Vivamus sed sodales odio, in faucibus
          sapien.
        </p>

        <Grid
          container
          spacing={2}
          style={{
            width: "90%",
            margin: "auto",
            marginTop: "50px",
          }}
        >
          <Grid item md={4}>
            <div className="shop-card-container">
              <div className="shop-card-image-container">
                <img src={jeep1} alt="" style={{ width: "80%" }} />
              </div>
              <div className="shop-card-details">
                <Link
                  to="/product-details"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <p className="shop-card-details-heading">Donec commodo</p>
                </Link>
                <p className="shop-card-details-description">
                  Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada
                  magna at leo scelerisque molestie.
                </p>
                <p className="shop-card-details-price">$ 100</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/build-your-own"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button className="main-button">Build Buy Your Own</Button>
                  </Link>
                  <LocalMall
                    style={{
                      marginLeft: "10px",
                      color: "#FF3132",
                      fontSize: "36px",
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="shop-card-container">
              <div className="shop-card-image-container">
                <img src={jeep2} alt="" style={{ width: "80%" }} />
              </div>
              <div className="shop-card-details">
                <Link
                  to="/product-details"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <p className="shop-card-details-heading">Donec commodo</p>
                </Link>
                <p className="shop-card-details-description">
                  Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada
                  magna at leo scelerisque molestie.
                </p>
                <p className="shop-card-details-price">$ 100</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/build-your-own"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button className="main-button">Build Buy Your Own</Button>
                  </Link>
                  <LocalMall
                    style={{
                      marginLeft: "10px",
                      color: "#FF3132",
                      fontSize: "36px",
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="shop-card-container">
              <div className="shop-card-image-container">
                <img src={jeep3} alt="" style={{ width: "80%" }} />
              </div>
              <div className="shop-card-details">
                <Link
                  to="/product-details"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <p className="shop-card-details-heading">Donec commodo</p>
                </Link>
                <p className="shop-card-details-description">
                  Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada
                  magna at leo scelerisque molestie.
                </p>
                <p className="shop-card-details-price">$ 100</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/build-your-own"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button className="main-button">Build Buy Your Own</Button>
                  </Link>
                  <LocalMall
                    style={{
                      marginLeft: "10px",
                      color: "#FF3132",
                      fontSize: "36px",
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="shop-card-container">
              <div className="shop-card-image-container">
                <img src={jeep1} alt="" style={{ width: "80%" }} />
              </div>
              <div className="shop-card-details">
                <Link
                  to="/product-details"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <p className="shop-card-details-heading">Donec commodo</p>
                </Link>
                <p className="shop-card-details-description">
                  Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada
                  magna at leo scelerisque molestie.
                </p>
                <p className="shop-card-details-price">$ 100</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/build-your-own"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button className="main-button">Build Buy Your Own</Button>
                  </Link>
                  <LocalMall
                    style={{
                      marginLeft: "10px",
                      color: "#FF3132",
                      fontSize: "36px",
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="shop-card-container">
              <div className="shop-card-image-container">
                <img src={jeep2} alt="" style={{ width: "80%" }} />
              </div>
              <div className="shop-card-details">
                <Link
                  to="/product-details"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <p className="shop-card-details-heading">Donec commodo</p>
                </Link>
                <p className="shop-card-details-description">
                  Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada
                  magna at leo scelerisque molestie.
                </p>
                <p className="shop-card-details-price">$ 100</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/build-your-own"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button className="main-button">Build Buy Your Own</Button>
                  </Link>
                  <LocalMall
                    style={{
                      marginLeft: "10px",
                      color: "#FF3132",
                      fontSize: "36px",
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="shop-card-container">
              <div className="shop-card-image-container">
                <img src={jeep3} alt="" style={{ width: "80%" }} />
              </div>
              <div className="shop-card-details">
                <Link
                  to="/product-details"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <p className="shop-card-details-heading">Donec commodo</p>
                </Link>
                <p className="shop-card-details-description">
                  Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada
                  magna at leo scelerisque molestie.
                </p>
                <p className="shop-card-details-price">$ 100</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/build-your-own"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button className="main-button">Build Buy Your Own</Button>
                  </Link>
                  <LocalMall
                    style={{
                      marginLeft: "10px",
                      color: "#FF3132",
                      fontSize: "36px",
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="shop-card-container">
              <div className="shop-card-image-container">
                <img src={jeep1} alt="" style={{ width: "80%" }} />
              </div>
              <div className="shop-card-details">
                <Link
                  to="/product-details"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <p className="shop-card-details-heading">Donec commodo</p>
                </Link>
                <p className="shop-card-details-description">
                  Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada
                  magna at leo scelerisque molestie.
                </p>
                <p className="shop-card-details-price">$ 100</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/build-your-own"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button className="main-button">Build Buy Your Own</Button>
                  </Link>
                  <LocalMall
                    style={{
                      marginLeft: "10px",
                      color: "#FF3132",
                      fontSize: "36px",
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="shop-card-container">
              <div className="shop-card-image-container">
                <img src={jeep2} alt="" style={{ width: "80%" }} />
              </div>
              <div className="shop-card-details">
                <Link
                  to="/product-details"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <p className="shop-card-details-heading">Donec commodo</p>
                </Link>
                <p className="shop-card-details-description">
                  Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada
                  magna at leo scelerisque molestie.
                </p>
                <p className="shop-card-details-price">$ 100</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/build-your-own"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button className="main-button">Build Buy Your Own</Button>
                  </Link>
                  <LocalMall
                    style={{
                      marginLeft: "10px",
                      color: "#FF3132",
                      fontSize: "36px",
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="shop-card-container">
              <div className="shop-card-image-container">
                <img src={jeep3} alt="" style={{ width: "80%" }} />
              </div>
              <div className="shop-card-details">
                <Link
                  to="/product-details"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <p className="shop-card-details-heading">Donec commodo</p>
                </Link>
                <p className="shop-card-details-description">
                  Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada
                  magna at leo scelerisque molestie.
                </p>
                <p className="shop-card-details-price">$ 100</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/build-your-own"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button className="main-button">Build Buy Your Own</Button>
                  </Link>
                  <LocalMall
                    style={{
                      marginLeft: "10px",
                      color: "#FF3132",
                      fontSize: "36px",
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="mid-banner-2">
        <div>
          <p
            style={{
              fontWeight: "500",
              fontSize: "22px",
            }}
          >
            DRIVE BEYOND BOUNDARIES
          </p>
        </div>
        <p
          style={{
            fontWeight: "800",
            fontSize: "44px",
            lineHeight: "52px",
            textAlign: "center",
          }}
        >
          CUSTOMIZE DESIGN
        </p>
        <Link
          to="/build-your-own"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Button className="main-button">Customize Your Jeep</Button>
        </Link>
      </div>
    </div>
  );
};

export default Home;
