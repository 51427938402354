import React, { useState, useEffect, useRef } from "react";
import { Button, Grid } from "@mui/material";
import ReactPlayer from "react-player";
import {
  PlayCircle,
  ArrowBack,
  ArrowForward,
  Facebook,
  LinkedIn,
} from "@mui/icons-material";

import { Link } from "react-router-dom";
// import Banner from "../assets/svg/cover phone.svg";
import homeHero from "../assets/svg/homePageHero.svg";
import vision from "../assets/images/about-vision-vehicle.png";
import customizeJeep from "../assets/images/about-customize-jeep.png";
import team from "../assets/images/team.png";
import jeep1 from "../assets/images/demoProduct1.png";
import jeep2 from "../assets/images/demoProduct2.png";
import jeep3 from "../assets/images/demoProduct3.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const About = () => {
  const boxRef = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const btnpressprev = () => {
    if (boxRef.current) {
      const width = boxRef.current.clientWidth;
      setScrollLeft(scrollLeft - width);
    }
  };

  const btnpressnext = () => {
    if (boxRef.current) {
      const width = boxRef.current.clientWidth;
      console.log(width);
      setScrollLeft(scrollLeft + width);
    }
  };

  return (
    <div>
      <div className="shoppage-hero-container">
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "white",
              }}
            >
              <p
                style={{
                  fontWeight: "800",
                  fontSize: "44px",
                }}
              >
                ABOUT
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <p
                  style={{
                    fontSize: "22px",
                    fontWeight: "500",
                  }}
                >
                  HOME - ABOUT
                </p>
                <div className="divider-hero"></div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} className="hero-section-image">
            <img src={homeHero} alt="" style={{ width: "90%" }} />
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          width: "50%",
          height: 50,
          backgroundColor: "#272727",
          marginTop: -50,
          borderTopRightRadius: 50,
        }}
      ></div>

      <div className="about-vision-main-container">
        <Grid container spacing={2}>
          <Grid
            item
            md={5}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={vision} alt="" style={{ width: "100%", zIndex: 99 }} />
          </Grid>
          <Grid
            item
            md={7}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="about-vision-container">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="contact-form-heading">OUR VISION</p>
                <div className="about-driver1"></div>
              </div>
              <div className="about-vision-text">
                <p>
                  Vestibulum tellus orci, aliquam a elit at, rhoncus dictum
                  libero. Mauris cursus erat nec odio gravida finibus. Nulla et
                  mauris id lacus vestibulum consequat consequat at augue.
                  Pellentesque vehicula et felis quis interdum. Fusce ultrices,
                  neque nec auctor ultricies,
                </p>
              </div>
              <div className="about-vision-bullet-text">
                <div className="about-vision-bullet"></div>
                <p style={{ margin: "-10px 10px" }}>
                  Vestibulum tellus orci, aliquam a elit at, rhoncus dictum
                  libero. Mauris cursus erat nec odio gravida finibus.
                </p>
              </div>
              <div className="about-vision-bullet-text">
                <div className="about-vision-bullet"></div>
                <p style={{ margin: "-10px 10px" }}>
                  Vestibulum tellus orci, aliquam a elit at, rhoncus dictum
                  libero. Mauris cursus erat nec odio gravida finibus.
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="about-vision-background-container"></div>
      </div>

      <div style={{ zIndex: 999 }}>
        <Grid container spacing={1} className="our-team">
          <Grid
            item
            sm={12}
            xs={12}
            md={9}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "auto",
              padding: "0px 20px",
            }}
          >
            <p className="our-team-heading">MY 3D JEEP TEAM</p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: -20,
              }}
            >
              <button
                onClick={btnpressprev}
                className="featured-scroll-arrow-button"
              >
                <ArrowBack />
              </button>
              <button
                onClick={btnpressnext}
                className="featured-scroll-arrow-button"
              >
                <ArrowForward />
              </button>
            </div>
          </Grid>
          <Grid
            item
            sm={12}
            md={10}
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              marginTop: -40,
              margin: "auto",
            }}
          >
            <div className="product-carousel">
              <div
                className="product-container"
                ref={boxRef}
                style={{ scrollLeft: scrollLeft }}
              >
                <div className="our-team-card-container">
                  <div className="our-team-card-image-container">
                    <img src={team} alt="" style={{ width: "100%" }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <p style={{ fontSize: 22, fontWeight: 800, margin: 0 }}>
                        JOHN SMITH
                      </p>
                      <p style={{ fontSize: 14, fontWeight: 400, margin: 0 }}>
                        Director/CEO
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <LinkedIn style={{ color: "#FF3132" }} />
                      </button>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <Facebook style={{ color: "#FF3132" }} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="our-team-card-container">
                  <div className="our-team-card-image-container">
                    <img src={team} alt="" style={{ width: "100%" }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <p style={{ fontSize: 22, fontWeight: 800, margin: 0 }}>
                        JOHN SMITH
                      </p>
                      <p style={{ fontSize: 14, fontWeight: 400, margin: 0 }}>
                        Director/CEO
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <LinkedIn style={{ color: "#FF3132" }} />
                      </button>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <Facebook style={{ color: "#FF3132" }} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="our-team-card-container">
                  <div className="our-team-card-image-container">
                    <img src={team} alt="" style={{ width: "100%" }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <p style={{ fontSize: 22, fontWeight: 800, margin: 0 }}>
                        JOHN SMITH
                      </p>
                      <p style={{ fontSize: 14, fontWeight: 400, margin: 0 }}>
                        Director/CEO
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <LinkedIn style={{ color: "#FF3132" }} />
                      </button>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <Facebook style={{ color: "#FF3132" }} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="our-team-card-container">
                  <div className="our-team-card-image-container">
                    <img src={team} alt="" style={{ width: "100%" }} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <p style={{ fontSize: 22, fontWeight: 800, margin: 0 }}>
                        JOHN SMITH
                      </p>
                      <p style={{ fontSize: 14, fontWeight: 400, margin: 0 }}>
                        Director/CEO
                      </p>
                    </div>
                    <div style={{ display: "flex" }}>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <LinkedIn style={{ color: "#FF3132" }} />
                      </button>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <Facebook style={{ color: "#FF3132" }} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          height: "500px",
          width: "100%",
        }}
      >
        <ReactPlayer
          className="react-player"
          url="videos/demo-video.MP4"
          width="100%"
          height="100%"
          playIcon={<PlayCircle />}
          controls={true}
        />
      </div>

      <div>
        <Grid container spacing={2} className="mid-banner-1">
          <Grid
            item
            md={5}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "black",
              }}
            >
              <p className="customize-section-sub-heading">
                Elevate Your Drive, Customize in 3D
              </p>
              <p className="customize-section-heading">
                3D Customize Your Jeep Adventure!
              </p>
              <Link
                to="/build-your-own"
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button className="main-button">Customize Your Jeep</Button>
              </Link>
            </div>
          </Grid>
          <Grid
            item
            md={7}
            sm={12}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={customizeJeep} alt="" style={{ width: "80%" }} />
          </Grid>
        </Grid>
      </div>

      <div className="mid-banner-2">
        <div>
          <p
            style={{
              fontWeight: "500",
              fontSize: "22px",
            }}
          >
            DRIVE BEYOND BOUNDARIES
          </p>
        </div>
        <p
          style={{
            fontWeight: "800",
            fontSize: "44px",
            lineHeight: "52px",
            textAlign: "center",
          }}
        >
          CUSTOMIZE DESIGN
        </p>
        <Link
          to="/build-your-own"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Button className="main-button">Customize Your Jeep</Button>
        </Link>
      </div>
    </div>
  );
};

export default About;
