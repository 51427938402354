import React, { useState, useEffect, useRef } from "react";
import { Button, Divider, Grid } from "@mui/material";
import { LocalMall, Star } from "@mui/icons-material";
import Slider from "@mui/material/Slider";

import customizeJeep from "../assets/images/about-customize-jeep.png";
import bumper from "../assets/images/bumper.png";
import body from "../assets/images/body.png";
import rims from "../assets/images/rims.png";
import tyre from "../assets/images/tyre.png";

import ModelRenderer from "../Components/3DModel";

const BuildYourOwn = () => {
  const [grillColor, setGrillColor] = useState(null);
  const [bumperColor, setBumperColor] = useState(null);
  const [grillIndex, setGrillIndex] = useState(-1);
  const [bumperIndex, setBumperIndex] = useState(-1);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [products, setProducts] = useState([
    { name: "DONEC COMMANDO", imageIndex: 1 },
    { name: "DONEC COMMANDO", imageIndex: 2 },
    { name: "DONEC COMMANDO", imageIndex: 3 },
  ]);
  return (
    <div>
      <div className="product-detail-hero-container">
        <div style={{ width: "80%", backgroundColor: "#111111" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              color: "#FFFFFF",
            }}
          >
            <p style={{ margin: "0px 10px" }}>DONEC COMMANDO</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "white",
            }}
          >
            <p className="product-detail-title">BUILD BUY YOUR OWN</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "white",
              }}
            >
              <p style={{ margin: 0, color: "white" }}>Add To Cart</p>
              <LocalMall
                style={{
                  margin: "0px 10px",
                  color: "#FF3132",
                  fontSize: "28px",
                }}
              />
              <Button
                style={{ width: 150, height: 50, borderRadius: 0 }}
                className="main-button"
              >
                EXTERIOR
              </Button>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "80%",
            height: "80vh",
            backgroundColor: "#202020",
            textAlign: "center",
          }}
        >
          {/* <img
            src={customizeJeep}
            alt=""
            style={{ width: "70%", margin: "40px 0px" }}
          /> */}
          <ModelRenderer
            grillIndex={grillIndex}
            grillColor={grillColor}
            bumperColor={bumperColor}
            bumperIndex={bumperIndex}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
            backgroundColor: "#202020",
          }}
        >
          <Button className="build-main-button-active">ALL</Button>
          <Button className="build-main-button">EXTERIOR(7)</Button>
          <Button className="build-main-button">TRD(6)</Button>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
            backgroundColor: "#202020",
            padding: "40px 0px",
          }}
        >
          <div className="build-your-own-card">
            <div className="build-your-own-image">
              <img src={bumper} />
            </div>
            <p className="build-your-own-card-title">BUMPER</p>
            <p className="build-your-own-card-price">$2,500</p>
            <Button className="main-button">+ Add</Button>
          </div>
          <div className="build-your-own-card">
            <div className="build-your-own-image">
              <img src={body} />
            </div>
            <p className="build-your-own-card-title">BODY</p>
            <p className="build-your-own-card-price">$4,500</p>
            <Button className="main-button">+ Add</Button>
          </div>
          <div className="build-your-own-card">
            <div className="build-your-own-image">
              <img src={rims} />
            </div>
            <p className="build-your-own-card-title">RIMS</p>
            <p className="build-your-own-card-price">$800</p>
            <Button className="main-button">+ Add</Button>
          </div>
          <div className="build-your-own-card">
            <div className="build-your-own-image">
              <img src={tyre} />
            </div>
            <p className="build-your-own-card-title">TIRE</p>
            <p className="build-your-own-card-price">$1,200</p>
            <Button className="main-button">+ Add</Button>
          </div>
          <div className="build-your-own-card">
            <div className="build-your-own-image">
              <img src={bumper} />
            </div>
            <p className="build-your-own-card-title">BUMPER</p>
            <p className="build-your-own-card-price">$2,500</p>
            <Button className="main-button">+ Add</Button>
          </div>
        </div>

        <div
          style={{
            width: "80%",
            backgroundColor: "#202020",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 20,
            }}
          >
            <p
              style={{
                fontWeight: "800",
                fontSize: "44px",
                lineHeight: "52px",
                marginLeft: 20,
                color: "white",
              }}
            >
              PRICE DETAIL
            </p>
            <div className="product-detail-header-divider"></div>
          </div>
          <table className="table-container">
            <thead className="table-head">
              <th className="table-column table-main-title">VEHICLE PARTS</th>
              <th className="table-column mid-column table-main-title">
                DESCRIPTIONS
              </th>
              <th className="table-column table-main-title">PRICING</th>
            </thead>
            <tbody>
              <tr className="table-row">
                <td className="table-body-column table-main-title">BUMPER</td>
                <td className="table-body-column table-description">
                  Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada
                  magna at leo scelerisque molestie.
                </td>
                <td className="table-body-column">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "80%",
                      margin: "auto",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 32,
                        fontWeight: 800,
                        margin: 0,
                        color: "#FF3433",
                      }}
                    >
                      $2,500
                    </p>
                    <Button
                      style={{
                        fontSize: 32,
                        height: 30,
                      }}
                      className="main-button"
                    >
                      -
                    </Button>
                  </div>
                </td>
              </tr>
              <tr className="table-row">
                <td className="table-body-column table-main-title">BODY</td>
                <td className="table-body-column table-description">
                  Lorem ipsum dolor sit amet, consectetur elit. Sed malesuada
                  magna at leo scelerisque molestie.
                </td>
                <td className="table-body-column">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "80%",
                      margin: "auto",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 32,
                        fontWeight: 800,
                        margin: 0,
                        color: "#FF3433",
                      }}
                    >
                      $3,200
                    </p>
                    <Button
                      style={{
                        fontSize: 32,
                        height: 30,
                      }}
                      className="main-button"
                    >
                      -
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            width: "80%",
            backgroundColor: "#ffffff",
            textAlign: "center",
            padding: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 20,
            }}
          >
            <p
              style={{
                fontWeight: "800",
                fontSize: "44px",
                lineHeight: "52px",
                marginLeft: 20,
              }}
            >
              PRODUCT DETAIL
            </p>
            <div className="product-detail-header-divider"></div>
          </div>

          <div style={{ textAlign: "left", padding: "0px 20px" }}>
            <div className="about-vision-text">
              <p style={{ margin: "10px 10px" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Maecenas elementum felis at ligula ullamcorper. Vivamus vitae
                nisl libero. Pellentesque imperdiet ipsum ut sollicitudin
                efficitur. Nulla aliquet erat vel scelerisque efficitur. Etiam
                molestie neque lacus. Quisque quis ipsum at arcu faucibus
                rutrum. Quisque in dictum ipsum.
              </p>
            </div>
            <div className="about-vision-text" style={{ marginTop: 30 }}>
              <div className="product-detail-bullet"></div>
              <p style={{ margin: "-10px 10px" }}>
                Vestibulum tellus orci, aliquam a elit at, rhoncus dictum
                libero. Mauris cursus erat nec odio gravida finibus. Nullmauris
                id lacus vestibulum consequat consequat at augue.
              </p>
            </div>
            <div className="about-vision-text" style={{ marginTop: 30 }}>
              <div className="product-detail-bullet"></div>
              <p style={{ margin: "-10px 10px" }}>
                Vestibulum tellus orci, aliquam a elit at, rhoncus dictum
                libero. Mauris cursus erat nec odio gravida finibus. Nullmauris
                id lacus vestibulum consequat consequat at augue.
              </p>
            </div>
            <div className="about-vision-text" style={{ marginTop: 30 }}>
              <div className="product-detail-bullet"></div>
              <p style={{ margin: "-10px 10px" }}>
                Vestibulum tellus orci, aliquam a elit at, rhoncus dictum
                libero. Mauris cursus erat nec odio gravida finibus. Nullmauris
                id lacus vestibulum consequat consequat at augue.
              </p>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "20px 0px",
              }}
            >
              <div style={{ margin: "0px 10px" }}>
                <p style={{ fontSize: 18, margin: 0, marginBottom: -10 }}>
                  Vehicle Price
                </p>
                <p
                  style={{
                    fontSize: 32,
                    fontWeight: 800,
                    margin: 0,
                    color: "#FF3433",
                  }}
                >
                  $ 25,500
                </p>
              </div>

              <Button
                style={{
                  width: 230,
                  height: 50,
                  borderRadius: 0,
                  margin: "0px 5px",
                }}
                className="main-button"
              >
                Add To Cart
                <LocalMall
                  style={{
                    margin: "0px 10px",
                    color: "#ffffff",
                    fontSize: "28px",
                  }}
                />
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="mid-banner-2">
        <div>
          <p
            style={{
              fontWeight: "500",
              fontSize: "22px",
            }}
          >
            DRIVE BEYOND BOUNDARIES
          </p>
        </div>
        <p
          style={{
            fontWeight: "800",
            fontSize: "44px",
            lineHeight: "52px",
            textAlign: "center",
          }}
        >
          CUSTOMIZE DESIGN
        </p>
        <Button className="main-button">CONTACT US</Button>
      </div>
    </div>
  );
};

export default BuildYourOwn;
